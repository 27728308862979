import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Avatar from "@mui/material/Avatar";
import Translate from "../../utils/Translate";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DotMenu from "../DotMenu";
import {
  DocumentType,
  findSentCommunication,
  MediaType,
  CommunicationRole,
} from "./CourseCommunication";
import Language from "./dialogs/Language";
import DetailedResults from "./dialogs/DetailedResults";
import SendPassword, { messageTypes } from "./dialogs/SendPassword";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EmailIcon from "@mui/icons-material/Email";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SchoolIcon from "@mui/icons-material/School";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LanguageIcon from "@mui/icons-material/Language";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import QuizIcon from "@mui/icons-material/Quiz";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useApi } from "../../utils/Api";
import * as React from "react";
import Preloader from "../Preloader";
import RemoveCourseParticipant from "./dialogs/RemoveCourseParticipant";
import PersonCrud from "../actors/crud/PersonCrud";
import { hasSomeAccess, accessKeys, hasAccess } from "../../utils/userAccess";
import { useSnackbar } from "notistack";
import BadgeIcon from "@mui/icons-material/Badge";
import { formatDate, tryFormatDate } from "../../utils/dateTimeFormat";
import YesOrNoDialog from "../YesOrNoDialog";
import { useNavigate, useLocation } from "react-router-dom";
import DuplicateSelfRegActorMerge from "./DuplicateSelfRegActorMerge";
import SendSms from "./dialogs/SendSms";
import SmsIcon from "@mui/icons-material/Sms";
import { Link } from "react-router-dom";
import CourseInvitation from "./dialogs/CourseInvitation";
import MessageIcon from "@mui/icons-material/Message";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import { stringToColor } from "../../utils/formatString";
import ExtraFinalExamDialog from "./dialogs/ExtraFinalExamDialog";
import ExtraFinalExamAndSendFlow from "./dialogs/ExtraFinalExamAndSendFlow";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { formatInsertArguments } from "../../utils/formatString";
import Personnummer from "personnummer";
import ManualAuthentication from "./ManualAuthentication";
import { Authenticated } from "./Authentication";

const getSendPasswordProps = (row, props) => ({
  action: "sendToParticipants",
  eLearningType: props.eLearningType,
  courseId: props.courseId,
  courseBookings: [
    {
      id: props.id,
      courseId: props.courseId,
      courseBookingId: props.id,
      companyName: props.companyName,
      contactActor: props.contactActor,
      courseParticipants: [
        {
          actorId: row.actorId,
          name: row.actorName,
          email: row.actorEmail,
          profilePictureUrl: row.profilePictureUrl,
          eLearningLicenseId: row.eLearningLicenseId,
          courseParticipantId: row.courseParticipantId,
        },
      ],
    },
  ],
});

async function postAddOrUpdateStudent(api, student) {
  if (!student.courseBookingId || !student.actorId) {
    throw new Error("Missing data for student");
  }

  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/participants`,
    student,
    "POST"
  );

  return !!response;
}

async function getStudentActiveCourseParticipantId(api, actorId, courseId) {
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/actor/${actorId}/has-active-bookings/`,
    false,
    "GET"
  );

  return response && response.length > 0 ? response[0] : null;
}

async function postMoveStudent(
  api,
  courseBookingId,
  courseParticipantIdToMove,
  courseParticipantIdToReplace,
  eLearningLicenseId,
  isPrivateBooking,
  enqueueSnackbar,
  closeSnackbar
) {
  if (isPrivateBooking) {
    throw new Error("Don't move students for private bookings");
  }

  const dto = {
    courseBookingId,
    courseParticipantIdToMove,
    eLearningLicenseId,
    courseParticipantIdToReplace:
      courseParticipantIdToReplace > 0 ? courseParticipantIdToReplace : null,
  };

  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/participants/move/`,
    dto,
    "POST"
  );

  if (!response || !response.isSuccessful) {
    const errorMessage = enqueueSnackbar(
      Translate.get(response.errorMessageTranslationKey ?? "SomethingFailed"),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );
    return false;
  }

  return true;
}

async function postUndoAddExtraFinalExam(
  eLearningLicenseId,
  newFinalExamNumber,
  api,
  loadCourse,
  toggleLoading
) {
  const dto = {
    eLearningLicenseId,
    newFinalExamNumber,
  };

  toggleLoading(true);
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}licenses/elearning/exam/undo-extra/`,
    dto,
    "POST"
  );
  toggleLoading(false);
  if (!!response && response.isSuccessful) {
    loadCourse();
  }
}

const getDotMenu = (
  api,
  student,
  props,
  locked,
  finalExam,
  progress,
  toggleLoading,
  loadCourse,
  enqueueSnackbar,
  closeSnackbar,
  handleSaveStudent,
  closePersonCrudAndReload
) => {
  let disabledLock = false;
  let disabledDelete = false;

  if (finalExam !== null) {
    locked = true;
    disabledLock = true;
  }

  if (progress) {
    disabledDelete = true;
  }

  let isELearningCourse = eLearningCourse(props.eLearningType);
  const hasFailedFinalExam =
    !!student.finalExamEndDate && !student.finalExamDoneOk;
  const hasExtraFinalExam = student.currentFinalExamNumber > 1;
  const hasNewExtraFinalExam = hasExtraFinalExam && !student.finalExamStartDate;

  // Increasing this number is supported, but we decided to cap it here for now
  const hasMaxNumberOfExtraExams = student.currentFinalExamNumber >= 2;

  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditPersonalInfo"),
      route: false,
      disabled: student.isDummyLicense,
      component: (
        <PersonCrud
          actorId={student.actorId}
          actorRole="student"
          parentActorId={props.companyActorId}
          isMatchingActorsDisabled={progress > 0 || props.isPrivateBooking}
          onActorSaved={async (actor) =>
            await handleSaveStudent(
              student.actorId,
              actor.actorId,
              props.id,
              student.courseParticipantId,
              student.eLearningLicenseId,
              student.actorOrDummyId
            )
          }
          onCancel={closePersonCrudAndReload}
          // We will close it from this component
          onClose={() => { }}
        />
      ),
    },
    {
      icon: <VpnKeyIcon />,
      text: Translate.get("SendPassword"),
      dialogHeader: Translate.get("SendPassword"),
      route: false,
      component: (
        <SendPassword
          {...getSendPasswordProps(student, props)}
          messageType={messageTypes.eLearningInvitation}
        />
      ),
      hide: !isELearningCourse,
      customClass: "mediumCourseDialog",
      disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <AddAlertIcon />,
      text: Translate.get("SendCourseInvitation"),
      dialogHeader: Translate.get("SendCourseInvitation"),
      route: false,
      component: (
        <CourseInvitation
          {...getSendPasswordProps(student, props)}
          courseFromDate={props.courseFromDate}
        />
      ),
      hide: !hasAccess(accessKeys.advancedPackage),
      customClass: "mediumCourseDialog",
      disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <SmsIcon />,
      text: Translate.get("SendSms2"),
      route: false,
      dialogHeader: Translate.get("SendSms2"),
      component: hasAccess(accessKeys.advancedPackage) ? (
        <SendSms
          {...{
            student,
            courseId: props.courseId,
          }}
        />
      ) : null,
      hide: !hasAccess(accessKeys.advancedPackage),
      disabled: false,
      customClass: "mediumCourseDialog",
    },
    {
      icon: <QuizIcon />,
      text: Translate.get("DetailedResult"),
      route: false,
      component: (
        <DetailedResults eLearningLicenseId={student.eLearningLicenseId} />
      ),
      hide: !isELearningCourse,
      disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <SchoolIcon />,
      text: Translate.get("DownloadCertificate"),
      disabled:
        progress !== 100 || !!student.dummyType || student.isDummyLicense,
      route: false,
      onClick: () =>
        getPocPdf(
          api,
          student.eLearningLicenseId,
          student.actorName,
          toggleLoading,
          enqueueSnackbar,
          closeSnackbar
        ),
      hide: !isELearningCourse,
    },
    {
      icon: <NoteAddIcon />,
      text: Translate.get("AddExtraFinalExamLabel"),
      route: false,
      dialogHeader: Translate.get("AddExtraFinalExamLabel"),
      component: (
        <ExtraFinalExamAndSendFlow
          eLearningLicenseId={student.eLearningLicenseId}
          newFinalExamNumber={student.currentFinalExamNumber + 1}
          sendPasswordProps={getSendPasswordProps(student, props)}
          loadCourse={loadCourse}
        />
      ),
      hide:
        !isELearningCourse ||
        !student.finalExamEndDate ||
        student.finalExamDoneOk ||
        hasMaxNumberOfExtraExams,
      disabled: !hasFailedFinalExam || hasMaxNumberOfExtraExams,
    },
    {
      icon: <NoteAddIcon />,
      text: Translate.get("UndoAddExtraFinalExamLabel"),
      route: false,
      onClick: () =>
        postUndoAddExtraFinalExam(
          student.eLearningLicenseId,
          student.currentFinalExamNumber - 1,
          api,
          loadCourse,
          toggleLoading
        ),
      hide: !isELearningCourse || !hasExtraFinalExam,
      disabled: !hasNewExtraFinalExam,
    },
    {
      icon: <EmailIcon />,
      text: Translate.get("SendExtraExamEmailLabel"),
      route: false,
      dialogHeader: Translate.get("SendExtraExamEmailLabel"),
      component: (
        <SendPassword
          {...getSendPasswordProps(student, props)}
          messageType={messageTypes.extraExamInfo}
          loadCourse={loadCourse}
        />
      ),
      hide:
        hasAccess(accessKeys.englishCompany) ||
        !isELearningCourse ||
        !hasExtraFinalExam,
      disabled: !!student.finalExamEndDate,
    },
    {
      icon: locked ? <LockOpenIcon /> : <LockIcon />,
      text: locked
        ? Translate.get("UnlockFinalExam")
        : Translate.get("LockFinalExam"),
      disabled: disabledLock,
      route: false,
      onClick: () =>
        toggleFinalExamLock(
          api,
          [student.eLearningLicenseId],
          props.locked,
          toggleLoading,
          loadCourse,
          enqueueSnackbar,
          closeSnackbar
        ),
      hide: !isELearningCourse || !!student.finalExamEndDate,
    },
    {
      icon: <LanguageIcon />,
      text: Translate.get("ChooseLanguage"),
      route: false,
      dialogHeader: Translate.get("ChooseLanguage"),
      component: (
        <Language
          {...props}
          eLearningLicenseIds={[student.eLearningLicenseId]}
          loadCourse={loadCourse}
        />
      ),
      hide: !isELearningCourse,
      customClass: "smallCourseDialog",
      //disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <HowToRegIcon />,
      text: Translate.get("ManualAuth"),
      disabled: student.dummyType,
      route: false,
      dialogHeader: Translate.get("ManualAuth"),
      component: (
        <ManualAuthentication loadCourse={loadCourse} courseParticipantId={student.courseParticipantId} existingManualAuths={student.manualAuthentications} />
      ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      )
    },
    {
      icon: <QrCode2Icon />,
      text: Translate.get("SelfReg"),
      disabled: student.dummyType,
      route: false,
      dialogHeader: Translate.get("SelfReg"),
      onClick: () =>
        window.open(
          `${window.location.origin}/dashboard/course/${props.courseId}/${props.id}/${student.actorId}/selfreg`,
          "_blank"
        ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      ),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeleteStudent"),
      disabled:
        disabledDelete || student.dummyType === 2 || student.actorLicense,
      route: false,
      dialogHeader:
        props.numberOfParticipants == 1
          ? Translate.get("DeleteStudentAndBooking")
          : Translate.get("DeleteStudent"),
      component: (
        <RemoveCourseParticipant
          courseBookingId={props.id}
          students={[
            {
              actorName: student.actorName,
              courseParticipantId: student.courseParticipantId,
              eLearningLicenseId: student.eLearningLicenseId,
            },
          ]}
          bookedNumberOfStudents={props.numberOfParticipants}
          loadCourse={loadCourse}
        />
      ),
      hide: false,
      customClass: "smallCourseDialog",
    },
  ];
  return menudata;
};

function eLearningCourse(eLearningType) {
  if (
    eLearningType !== undefined &&
    eLearningType !== null &&
    eLearningType > 0
  ) {
    return true;
  }
  return false;
}

function getPocPdf(
  api,
  eLearningLicenseId,
  participantName,
  toggleLoading,
  enqueueSnackbar,
  closeSnackbar
) {
  toggleLoading(true);
  const fileName =
    participantName.replace(/\s+/g, "_") +
    "_" +
    Translate.get("GenericProofOfCompletionTitle");

  let serviceUrl =
    "licenses/elearning/proof-of-completion/pdf/" + eLearningLicenseId;
  api
    .fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
      false,
      "GET",
      false,
      false
    )
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    })
    .catch(function () {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    })
    .finally((f) => {
      toggleLoading(false);
    });
}

function toggleFinalExamLock(
  api,
  eLearningLicenseIds,
  locked,
  toggleLoading,
  loadCourse,
  enqueueSnackbar,
  closeSnackbar
) {
  toggleLoading(true);
  const reqObj = {
    eLearningLicenseIds: eLearningLicenseIds,
  };

  let serviceUrl = locked
    ? "licenses/elearning/exam/unlock"
    : "licenses/elearning/exam/lock";

  api
    .fetch(
      `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
      reqObj,
      "POST",
      false,
      true
    )
    .then((data) => {
      if (!data.isSuccessful) {
        throw new Error();
      }
    })
    .finally((f) => {
      toggleLoading(false);
      loadCourse();
    })
    .catch((ex) => {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    });
}

function preProcessStudent(student, index, courseBookingId) {
  let auth = student.authentication;
  let manAuths = student.manualAuthentications;

  // Här skall all utdata fixas som har felaktigt format från eport
  // //console.log('Userdata', row.actor || 'missing actor');
  ////console.log('Userdata', row);
  
  if (!student.actor) {
    // Gissa att denna del bara inträffar för E-leaning licenser
    student.actor = {};
    student.actor.doctypes = {};
    student.actor.actorName =
      student.dummyType === 1
        ? Translate.get("AvailableSlot")
        : Translate.get("RemovedUser");
    student.actor.actorEmail = null;
    student.actor.birthDate = "N/A";
    student.actor.gotMail = false;
    student.actor.utskick = false;
  }

  // Vi har inget actorId för users som ännu inte har registrerat sig,
  // men behöver något för att kunna skilja deltagarn-platserna åt
  student.actor.actorOrDummyId = student.actor.actorId ?? `tempId${index}`;

  // Platta till data
  student = Object.assign({}, student.actor, student.eLearningLicense, {
    courseParticipantId: student.courseParticipantId,
    dummyType: student.dummyType,
    actorLicense: student.actorLicense,
  });
  window.global.bookings[courseBookingId][student.actorOrDummyId] = student;

  student.authentication = auth;
  student.manualAuthentications = manAuths;

  return student;
}

const Comp = ({ rows, sentCommunications, bookingId, ...props }) => {
  window.global.bookings[bookingId] = {};
  const [students, setStudents] = React.useState(
    rows.map((row, index) =>
      preProcessStudent(row, index, bookingId)
    )
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  // This one is used to close PersonCrud opened via DotMenu. PersonCrud already has a similar method,
  // but we cannot use it, becuase we need to execute some functions before the reload. And the we cannot
  // refactor easily because it is based on url and navigation in DotMenu rather than react state.
  const closePersonCrudAndReload = () => {
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  React.useEffect(
    () =>
    {
      const processed = rows
        .map((row, index) =>
          preProcessStudent(row, index, bookingId));
      
      const sorted = processed.sort((a, b) =>
        a?.actorId && b?.actorId
              ? a.actorName?.localeCompare(b.actorName)
              : a?.actorId 
                ? -1 // b är null men inte a
                : b?.actorId 
                  ? 1 // a är null men inte b
                  : 0 // båda null
      );
      setStudents(sorted)
    },
    [rows, bookingId]
  );

  function toggleIsLoading(newValue) {
    setIsLoading(newValue);
  }

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1, color: props.hexColor }}>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={props.value}
            sx={props.sx}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const languageCodeEnum = {
    0: "English (N/A)",
    9: Translate.get("LanguageCodeEnum_English"),
    12: Translate.get("LanguageCodeEnum_French"),
    20: Translate.get("LanguageCodeEnum_Norwegian"),
    29: Translate.get("LanguageCodeEnum_Swedish"),
  };
  const LangDD = (props) => {
    /*const [lang, setLang] = useState(props.value);

    const handleChange = (event) => {
      setLang(event.target.value);
    };
    return (
      <FormControl fullWidth>
        <Select value={lang} onChange={handleChange}>
          <MenuItem value={9}>English</MenuItem>
          <MenuItem value={12}>French</MenuItem>
          <MenuItem value={20}>Norweigan</MenuItem>
          <MenuItem value={29}>Swedish</MenuItem>
        </Select>
      </FormControl>
    );*/

    return <>{languageCodeEnum[props.value || 0]}</>;
  };

  // If we can we will add the student to the course right away. But if we find the same person as a
  // student of the same course type we will show a dialog asking if we want to move the person from
  // the other course, or add the person as a new student on this course instance as well.
  async function handleSaveStudent(
    oldActorId,
    newActorId,
    courseBookingId,
    courseParticipantId,
    eLearningLicenseId,
    actorOrDummyId
  ) {
    const isUpdatingStudent = oldActorId === newActorId;
    const activeCourseParticipantId =
      !isUpdatingStudent &&
      (await getStudentActiveCourseParticipantId(
        api,
        newActorId,
        props.courseId
      ));
    if (isUpdatingStudent || !activeCourseParticipantId) {
      await postAddOrUpdateStudent(api, {
        courseBookingId,
        courseParticipantId,
        actorId: newActorId,
        eLearningLicense: { eLearningLicenseId },
      });
      closePersonCrudAndReload();
    } else {
      const studentIndex = students.findIndex(
        (s) => s.actorOrDummyId === actorOrDummyId
      );
      const newStudent = {
        activeCourseParticipantId, // This will make the YesOrNoDialog show up
        courseParticipantId,
        eLearningLicenseId,
        actorId: newActorId,
        actorOrDummyId: newActorId,
        doctypes: {},
      };
      const studentsCopy = [...students];
      studentsCopy.splice(studentIndex, 1, newStudent);
      setStudents(studentsCopy);
    }
  }

  async function handleAddAsNewStudent(student) {
    await postAddOrUpdateStudent(api, {
      courseBookingId: bookingId,
      courseParticipantId: student.courseParticipantId,
      actorId: student.actorId,
      eLearningLicense: { eLearningLicenseId: student.eLearningLicenseId },
    });
    closePersonCrudAndReload();
  }

  async function handleMoveExistingStudent(student) {
    await postMoveStudent(
      api,
      bookingId,
      student.activeCourseParticipantId,
      student.courseParticipantId,
      student.eLearningLicenseId,
      props.isPrivateBooking,
      enqueueSnackbar,
      closeSnackbar
    );
    closePersonCrudAndReload();
  }

  return (
    <>
      {isLoading && <Preloader />}
      <Box sx={{ width: "100%" }}>
        <Paper className="bookingPaper" sx={{ width: "100%", mb: 2 }}>
          {students.map((student, index) => {
            return (
              <Card key={index} className="bookingCard">
                <CardHeader
                  avatar={
                    <Avatar
                      alt={student.actorName}
                      src={student.profilePictureUrl + ""}
                      sx={{ bgcolor: stringToColor(student.actorName) }}
                    />
                  }
                  action={
                    /*  <UserMoreMenu
                      actorData={row}
                      courseBookingId={bookingId}
                    /> */
                    <DotMenu
                      content={getDotMenu(
                        api,
                        student,
                        {
                          courseId: props.courseId,
                          courseFromDate: props.courseFromDate,
                          eLearningType: props.eLearningType,
                          id: bookingId,
                          companyActorId: props.companyActorId,
                          companyName: props.companyName,
                          contactActor: props.contactActor,
                          locked: student.finalExamIsLocked,
                          selectedELearningLanguageCode:
                            student.eTruckLanguageCode,
                          allowedLanguages: props.langs,
                          numberOfParticipants: props.numberOfParticipants,
                          isPrivateBooking: props.isPrivateBooking,
                        },
                        student.finalExamIsLocked,
                        student.finalExamScore,
                        student.progressPercent,
                        toggleIsLoading,
                        props.loadCourse,
                        enqueueSnackbar,
                        closeSnackbar,
                        handleSaveStudent,
                        closePersonCrudAndReload
                      )}
                    />
                  }
                  //title={student.actorName}
                  title={
                    <Link
                      className="unLinkiFy"
                      to={"/dashboard/person/" + student.actorId}
                    >
                      {student.actorName}
                    </Link>
                  }
                  subheader={
                    <a
                      className="stdMailToLink"
                      href={"mailto:" + student.actorEmail}
                    >
                      {student.actorEmail}
                    </a>
                  } //{student.actorEmail}
                />

                <CardContent className="bookingBox">
                  {/* <div>
                    <Tooltip title={Translate.get("BirthDate")} arrow>
                      <CakeIcon />
                    </Tooltip>
                    <div>{student.birthDate}</div>
                  </div> */}
                  <div>
                    <Tooltip title={Translate.get("PersonalNo")} arrow>
                      <FingerprintIcon />
                    </Tooltip>
                    <div>{student.orgNo}</div>
                  </div>
                  <div>
                    <Tooltip title={Translate.get("InfoShort")} arrow>
                      <InfoIcon />
                    </Tooltip>
                    <Stack direction="row" spacing={1}>
                      {findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.Student) && (
                        <Tooltip
                          className="iconbox"
                          title={
                            findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.Student).bounced === false
                              ? `${Translate.get("PasswordSent")} ` +
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.Student).sendDate.substring(0, 10)
                              : Translate.get("EmailBounce")
                          }
                          arrow
                        >
                          <VpnKeyIcon
                            color={
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ETruckLicense, CommunicationRole.Student).bounced === true
                                ? "error"
                                : ""
                            }
                          />
                        </Tooltip>
                      )}
                      {findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ExtraExamInvitation, CommunicationRole.Student) && (
                        <Tooltip
                          className="iconbox"
                          title={
                            findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ExtraExamInvitation, CommunicationRole.Student).bounced === false
                              ? `${Translate.get("ExtraFinalExamInfoSent")} ` +
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ExtraExamInvitation, CommunicationRole.Student).sentDate.substring(0, 10)
                              : Translate.get("EmailBounce")
                          }
                          arrow
                        >
                          <NoteAddIcon
                            color={
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.ExtraExamInvitation, CommunicationRole.Student).bounced === true
                                ? "error"
                                : ""
                            }
                          />
                        </Tooltip>
                      )}
                      {findSentCommunication(sentCommunications, student.actorId, MediaType.Sms, DocumentType.FreeTextSMS, CommunicationRole.Student) && (
                        <Tooltip
                          className="iconbox"
                          title={
                            Translate.get("SmsSent") +
                            " " +
                            findSentCommunication(sentCommunications, student.actorId, MediaType.Sms, DocumentType.FreeTextSMS, CommunicationRole.Student).sendDate.substring(0, 10)
                          }
                          arrow
                        >
                          <SmsIcon />
                        </Tooltip>
                      )}
                      {/*   </div>
                      )} */}
                      {findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.Student) && (
                        <Tooltip
                          className="iconbox"
                          title={
                            findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.Student).bounced === true
                              ? Translate.get("EmailBounce")
                              : Translate.get("CourseInvitationSent") +
                              " " +
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.Student).sendDate.substring(0, 10)
                          }
                          arrow
                        >
                          <AddAlertIcon
                            color={
                              findSentCommunication(sentCommunications, student.actorId, MediaType.Email, DocumentType.CourseSummoning, CommunicationRole.Student).bounced === true
                                ? "error"
                                : ""
                            }
                          />
                        </Tooltip>
                      )}
                      {student.actorLicense && (
                        <Tooltip
                          className="iconbox"
                          title={
                            <div>
                              <div>{`${Translate.get(
                                "LicenseDate"
                              )}: ${formatDate(
                                student.actorLicense.validFrom
                              )}${student.actorLicense.validTo
                                  ? ` - ${formatDate(
                                    student.actorLicense.validTo
                                  )}`
                                  : ""
                                }`}</div>
                              <div>{`${Translate.get("LastPrintDate")}: ${student.actorLicense.lastPrintDate
                                  ? formatDate(
                                    student.actorLicense.lastPrintDate
                                  )
                                  : "-"
                                }`}</div>
                            </div>
                          }
                          arrow
                        >
                          <BadgeIcon />
                        </Tooltip>
                      )}

                      {Authenticated(student)}
                    </Stack>
                  </div>

                  {student.actorIdOfDuplicate && (
                    <DuplicateSelfRegActorMerge
                      actorId1={student.actorIdOfDuplicate}
                      actorId2={student.actorId}
                      descriptionText="DuplicateSelfRegMobile"
                    />
                  )}

                  {props.eLearningType && (
                    <>
                      <div>
                        <Tooltip title={Translate.get("Results")} arrow>
                          <EqualizerIcon />
                        </Tooltip>
                        <div>
                          <LinearProgressWithLabel
                            sx={{ width: "150px" }}
                            variant="determinate"
                            value={student.progressPercent}
                            hexColor={student.progressColor}
                          />
                        </div>
                      </div>
                      <div>
                        <Tooltip title={Translate.get("FinalTest")} arrow>
                          <SchoolIcon />
                        </Tooltip>
                        <div>
                          {student.finalExamScore !== null ? (
                            <Tooltip
                              title={
                                Translate.get("FinalExamDate") +
                                " " +
                                tryFormatDate(student.finalExamEndDate)
                              }
                              placement="top-start"
                            >
                              <Box
                                sx={{
                                  bgcolor: student.finalExamColor,
                                  width:
                                    student.currentFinalExamNumber > 1 &&
                                      !!student.finalExamEndDate
                                      ? "42px"
                                      : "38px",
                                  boxShadow:
                                    student.currentFinalExamNumber > 1 &&
                                      !!student.finalExamEndDate
                                      ? `2px 2px #f05032`
                                      : "none",
                                }}
                                className={
                                  "stdInfoRect" +
                                  (student.currentFinalExamNumber > 1 &&
                                    !!student.finalExamEndDate
                                    ? " shadowEdge"
                                    : "")
                                }
                              >
                                {student.finalExamScore}%
                              </Box>
                            </Tooltip>
                          ) : student.finalExamIsLocked ? (
                            <LockIcon />
                          ) : (
                            <LockOpenIcon />
                          )}
                        </div>
                      </div>
                      <div>
                        <Tooltip title={Translate.get("Password")} arrow>
                          <VpnKeyIcon />
                        </Tooltip>
                        <div>{student.eLearningLicenseId}</div>
                      </div>
                      <div>
                        <Tooltip title={Translate.get("Language")} arrow>
                          <LanguageIcon />
                        </Tooltip>
                        <div>
                          <LangDD value={student.eTruckLanguageCode} />
                        </div>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            );
          })}
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        <YesOrNoDialog
          open={students.some((s) => s.activeCourseParticipantId)}
          title={Translate.get("MoveStudentTitle")}
          text={Translate.get("MoveStudentQuestion")}
          onNo={async () => {
            const student = students.find((s) => s.activeCourseParticipantId);
            await handleAddAsNewStudent(student);
          }}
          onYes={async () => {
            const student = students.find((s) => s.activeCourseParticipantId);
            await handleMoveExistingStudent(student);
          }}
          noText={Translate.get("MoveStudentNo")}
          yesText={Translate.get("MoveStudentYes")}
          onCancel={() =>
            setStudents(
              students.map((s) => ({ ...s, activeCourseParticipantId: null }))
            )
          }
        />
      </Box>
    </>
  );
};
export default Comp;
