import Button from "@mui/material/Button";
import Translate from "../../utils/Translate";
import BankIdAuthentication from "../../components/bankId/BankIdAuthentication";
import { useEffect, useState } from "react";
import { getCookie } from "./Common";
import { useNavigate } from "react-router-dom";

export function BankId({
  setBankIdToken,
  setExistingActor,
  setGivenName,
  setSurName,
  setSsn,
  setMode,
}) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    let token = getCookie("selfRegToken");
    setToken(token);

    let id = getCookie("selfRegId");
    setId(id);
    setIsLoading(false);
  }, []);


  function onAuthenticationSuccess(loginType, response) {
    let existingActor = response.data.existingActor;
    let bankIdToken = response.data.bankIdToken;

    let givenName = response.data.givenName;
    let surName = response.data.surName;
    let ssn = response.data.ssn;

    setExistingActor(existingActor);
    setBankIdToken(bankIdToken);
    setGivenName(givenName);
    setSurName(surName);
    setSsn(ssn);

    setMode("Form");
  }

  function handleSkipBankIdAuthClick() {
    setMode("Form");
  }

  function handleBankIdInstructionsClick() {
    navigate("/self/bankidinstructions");
  }

  if (isLoading) {
    return <div></div>
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >

        <aside className='MuiCallout-root MuiCallout-warning' style={{ maxWidth: "15em", marginBottom: "2em" }}> {Translate.get("BankIDToNext")}</aside>

        <BankIdAuthentication
          startAuthenticationUrl={`${process.env.REACT_APP_MAIN_URL}self/bankId/authenticate`}
          onAuthenticationSuccess={onAuthenticationSuccess}
          postData={{ selfRegId: id }}
          token={token}
        />
        <Button
          style={{ marginTop: "20px" }}
          onClick={handleSkipBankIdAuthClick}
        >
          {Translate.get("DontHaveBankId")}
        </Button>

        <Button onClick={handleBankIdInstructionsClick} style={{marginTop: "1em"}}>
          {Translate.get("BankIdDoesNotOpen")}
        </Button>
      </div>
    </div>
  );
}
