import Button from "@mui/material/Button";
import {
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Link,
  Box,
  Menu,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { DataGridPro as DataGrid, GridToolbar } from "@mui/x-data-grid-pro";
import { useState, useCallback, useEffect, Fragment } from "react";
import Translate from "../../utils/Translate";
import { hasSomeAccess, accessKeys } from "../../utils/userAccess";
import AutocompleteSearchField from "../AutocompleteSearchField";
import DomainIcon from "@mui/icons-material/Domain";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ELearningLicenseCount from "../courses/bookings/ELearningLicenseCount";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { actorSearch, actorCompanyTypes } from "../../utils/actorSearch";
import { LoadingButton } from "@mui/lab";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import { Link as RouterLink } from "react-router-dom";
import { partType, isFreightPartType } from "../../utils/part";
import DotMenu from "../DotMenu";
import {
  AccountBalance,
  Add,
  AttachMoney,
  Clear,
  Computer,
  Delete,
  Download,
  Edit,
  Email,
  Error,
  Flag,
  FlagOutlined,
  Lock,
  Money,
  MoreHoriz,
  Numbers,
  Percent,
  Print,
  ShoppingCartCheckout,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { dataGridLang } from "../MemToolBar";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { orderStatus } from "../../utils/order";
import { formatMoney } from "../../utils/formatMoney";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  isValidDate,
  toSameDateTimeUTC,
} from "../../utils/dateTimeFormat";
import MenuToggleButton from "../MenuToggleButton";
import { priority } from "../../utils/priority";

export default function OrderFormAdvanced({
  order,
  orderLines,
  company,
  contacts,
  receiverCompany,
  receiverContacts,
  addresses,
  receiverCompanyAddresses,
  deliveryAgreements,
  paymentAgreements,
  modesOfTransport,
  currencies,
  handleDeliveryAddressSelected,
  companySearch,
  handleCompanySelected,
  handleReceiverCompanySelected,
  handleResetReceiverCompany,
  handleResetInvoiceReference,
  canChangeCompany,
  isExternalDataIdRequired,
  handleContactSelected,
  isServiceAgreement,
  isEPortLicense,
  handleAddOrderLine,
  handleEditOrderLine,
  handleRemoveOrderLine,
  handleAddOrEditFreight,
  handleOrderFieldChanged,
  handleSimpleOrderLineFieldChanged,
  downloadOrderConfirmation,
  downloadDeliveryNotification,
  downloadDeliveryNote,
  handleEmailOrderConfirmation,
  handlePrintDeliveryAddress,
  useSeparateReceiverCompany,
  setUseSeparateReceiverCompany,
  useSeparateInvoiceReference,
  setUseSeparateInvoiceReference,
  useTempReference,
  handleSetUseTempReference,
  handleSaveOrSendOrder,
  handleClose,
  hasOrderChanged,
}) {
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentAnchorEl, setDocumentAnchorEl] = useState(null);
  const documentOpen = Boolean(documentAnchorEl);

  const localizedTextsMap = dataGridLang();
  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up("lg"));

  function getDotMenuActions(orderLine) {
    return [
      ...(isFreightPartType(orderLine.part?.partType)
        ? [] // We edit freight using another button
        : [
            {
              icon: <Edit />,
              text: Translate.get("EditLabel"),
              disabled:
                !orderLine.canUpdate || isServiceAgreement || isEPortLicense,
              onClick: () => handleEditOrderLine(orderLine.orderLineId),
            },
          ]),
      {
        icon: orderLine.excludeFromStats ? <Visibility /> : <VisibilityOff />,
        text: orderLine.excludeFromStats
          ? Translate.get("IncludeOrderLineInStats")
          : Translate.get("ExcludeOrderLineFromStats"),
        disabled: !orderLine.canUpdate || !order.creditId || true, // TODO Stöd ej i BE än eftersom fältet bara finns i interna och är redan NOT NULL
        onClick: () =>
          handleSimpleOrderLineFieldChanged(
            orderLine.orderLineId,
            "excludeFromStats",
            !orderLine.excludeFromStats
          ),
      },
      {
        icon: <Delete />,
        text: Translate.get("Delete"),
        disabled: !orderLine.canUpdate || isServiceAgreement || isEPortLicense,
        onClick: () => handleRemoveOrderLine(orderLine.orderLineId),
      },
    ];
  }

  const columns = [
    {
      field: "partNo",
      flex: 0.3,
      headerName: Translate.get("PartNoLabel"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={Numbers}
          label={Translate.get("PartNoLabel")}
        />
      ),
      valueGetter: (params) => params.row.part?.partNo ?? "",
    },
    {
      field: "partId",
      flex: 1,
      headerName: Translate.get("Product"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ShoppingBasketIcon}
          label={Translate.get("Product")}
        />
      ),
      valueGetter: (params) => params.row.part?.description ?? "",
    },
    {
      field: "qty",
      flex: 0.3,
      headerName: Translate.get("Quantity"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ScatterPlotIcon}
          label={Translate.get("Quantity")}
        />
      ),
    },
    {
      field: "discountedPcsPrice",
      flex: 0.4,
      headerName: Translate.get("PricePerUnit"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={AttachMoney}
          label={Translate.get("PricePerUnit")}
        />
      ),
      valueGetter: (params) =>
        params.row.discountedPcsPrice
          ? params.row.discountedPcsPrice.toFixed(2)
          : "",
    },
    {
      field: "discount",
      flex: 0.4,
      headerName: Translate.get("Discount"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={Percent}
          label={Translate.get("Discount")}
        />
      ),
      valueGetter: (params) =>
        params.row.discount ? `${(params.row.discount * 100).toFixed(2)}%` : "",
    },
    {
      field: "price",
      flex: 0.4,
      headerName: Translate.get("Price"),
      renderHeader: () => (
        <IconAndLabelColumnHeader icon={Money} label={Translate.get("Price")} />
      ),
      valueGetter: (params) =>
        params.row.price ? params.row.price.toFixed(2) : "",
    },
    {
      field: "vat",
      flex: 0.2,
      headerName: Translate.get("VATText"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={AccountBalance}
          label={Translate.get("VATText")}
        />
      ),
      valueGetter: (params) =>
        params.row.vat
          ? `${
              (params.row.vat * 100) % 1 > 0
                ? (params.row.vat * 100).toFixed(1)
                : params.row.vat * 100
            }%`
          : "",
    },
    {
      field: "dotMenu",
      flex: 0.2,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      headerClassName: "hideSeparator",
      align: "right",
      hide: !getDotMenuActions,
      hideable: false,
      renderCell: (params) =>
        getDotMenuActions && (
          <DotMenu content={getDotMenuActions(params.row)} horizontal />
        ),
    },
  ];

  function isEmptyTempAddress() {
    return (
      !order.tempAddress ||
      (!order.tempAddress.street1 &&
        !order.tempAddress.street2 &&
        !order.tempAddress.cityCode &&
        !order.tempAddress.city)
    );
  }

  return (
    <>
      <DialogTitle>
        {order.orderId
          ? `${Translate.get("Order")} ${
              order.orderNo
            } (${Translate.getOrderStatus(order.status).toLowerCase()})`
          : Translate.get("CreateOrder")}
      </DialogTitle>
      <DialogContent sx={{ width: { lg: "1160px", xl: "1460px" } }}>
        <Grid container spacing={4}>
          <Grid item container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                item
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <h4>{Translate.get("Company")}</h4>
                </Grid>
                <Grid item direction="row">
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setUseSeparateReceiverCompany(event.target.checked);

                          // When we uncheck we will replace the actorIds of the
                          // receiver with those of the order's company
                          const resetToOrderCompany = !event.target.checked;
                          handleResetReceiverCompany(resetToOrderCompany);
                        }}
                        checked={useSeparateReceiverCompany}
                        disabled={!order?.canUpdate}
                      />
                    }
                    label={Translate.get("UseSeparateReceiverCompany")}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          setUseSeparateInvoiceReference(event.target.checked);

                          // When we uncheck we will replace the actorIds of the
                          // receiver with those of the order's company
                          const resetToOrderRef = !event.target.checked;
                          handleResetInvoiceReference(resetToOrderRef);
                        }}
                        checked={useSeparateInvoiceReference}
                        disabled={!order?.canUpdate}
                      />
                    }
                    label={Translate.get("UseSeparateInvoiceRef")}
                  />
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          handleSetUseTempReference(event.target.checked);
                        }}
                        checked={useTempReference}
                        disabled={!order?.canUpdate}
                      />
                    }
                    label={Translate.get("TempReference")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <AutocompleteSearchField
                  disabled={!canChangeCompany || order.orderId > 0}
                  label={Translate.get("Company")}
                  value={company ?? ""}
                  onValueChange={async (selectedCompany) =>
                    await handleCompanySelected(selectedCompany)
                  }
                  getOptionLabel={(option) => option?.actorName ?? ""}
                  renderOption={(option) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        externalActorNo={
                          option?.actorId.toString() +
                          (option?.externalActorNo
                            ? ` (${option?.externalActorNo})`
                            : "")
                        }
                        addressStreet={option?.addressStreet}
                        addressZipCode={option?.addressZipCode}
                        addressCity={option?.addressCity}
                      />
                      {(option.externalDataId || option.observationNote) && (
                        <Stack
                          direction="column"
                          justifyContent={
                            option.externalDataId && option.observationNote
                              ? "space-between"
                              : option.externalDataId
                              ? "flex-start"
                              : "flex-end"
                          }
                        >
                          {option.externalDataId && (
                            <Box display="flex" width="80px" gap={1}>
                              <Computer />
                              <Box>{option.externalDataId ?? ""}</Box>
                            </Box>
                          )}
                          {option.observationNote && (
                            <Tooltip title={option.observationNote} arrow>
                              <Error sx={{ color: "red" }} />
                            </Tooltip>
                          )}
                        </Stack>
                      )}
                    </Box>
                  )}
                  keyPropName="actorId"
                  requireSelection={true}
                  search={companySearch}
                  createNewValue={(text) => ({
                    actorName: text,
                  })}
                  textFieldProps={{
                    required: true,
                    error:
                      !!company &&
                      isExternalDataIdRequired() &&
                      !company?.externalDataId,
                    helperText:
                      !!company &&
                      isExternalDataIdRequired() &&
                      !company?.externalDataId
                        ? Translate.get("CompanyHasNoDataId")
                        : "",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required>
                  <InputLabel id="contact-select-label">
                    {Translate.get("YourReference")}
                  </InputLabel>
                  <Select
                    required
                    disabled={
                      !order.canUpdate || isServiceAgreement || isEPortLicense
                    }
                    labelId="contact-select-label"
                    id="contact-select"
                    value={order?.actorRefId ?? ""}
                    label={Translate.get("YourReference")}
                    onChange={handleContactSelected}
                  >
                    {contacts &&
                      contacts.map((contact) => (
                        <MenuItem key={contact.actorId} value={contact.actorId}>
                          {contact.actorName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {useSeparateReceiverCompany && (
              <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
                <Grid item xs={12} md={6}>
                  <AutocompleteSearchField
                    disabled={!order.canUpdate}
                    label={Translate.get("ReceiverCompany")}
                    value={receiverCompany ?? ""}
                    onValueChange={handleReceiverCompanySelected}
                    getOptionLabel={(option) => option?.actorName ?? ""}
                    renderOption={(option) => (
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        externalActorNo={
                          option?.actorId.toString() +
                          (option?.externalActorNo
                            ? ` (${option?.externalActorNo})`
                            : "")
                        }
                        addressStreet={option?.addressStreet}
                        addressZipCode={option?.addressZipCode}
                        addressCity={option?.addressCity}
                      />
                    )}
                    keyPropName="actorId"
                    requireSelection={true}
                    search={companySearch}
                    createNewValue={(text) => ({
                      actorName: text,
                    })}
                    textFieldProps={{
                      error:
                        !!receiverCompany &&
                        isExternalDataIdRequired() &&
                        !receiverCompany?.externalDataId,
                      helperText:
                        !!receiverCompany &&
                        isExternalDataIdRequired() &&
                        !receiverCompany?.externalDataId
                          ? Translate.get("CompanyHasNoDataId")
                          : "",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="receiver-contact-select-label">
                      {Translate.get("ReceiverRef")}
                    </InputLabel>
                    <Select
                      disabled={
                        !order.canUpdate ||
                        isServiceAgreement ||
                        isEPortLicense ||
                        useTempReference
                      }
                      labelId="receiver-contact-select-label"
                      id="receiver-contact-select"
                      value={order?.receiverActorRefId ?? ""}
                      label={Translate.get("ReceiverRef")}
                      onChange={(event) =>
                        handleOrderFieldChanged(
                          "receiverActorRefId",
                          event.target.value
                        )
                      }
                    >
                      {receiverContacts &&
                        receiverContacts.map((contact) => (
                          <MenuItem
                            key={contact.actorId}
                            value={contact.actorId}
                          >
                            {contact.actorName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {useTempReference && (
              <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
                <Grid item xs={12} md={6}>
                  {/* Empt item on purpose */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!order.canUpdate}
                    fullWidth
                    value={order?.tempReferenceText ?? ""}
                    InputProps={{ spellCheck: false }}
                    onChange={(event) =>
                      handleOrderFieldChanged(
                        "tempReferenceText",
                        event.target.value
                      )
                    }
                    label={Translate.get("TempReference")}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            )}

            {useSeparateInvoiceReference && (
              <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
                <Grid item xs={12} md={6}>
                  {/* Empty item on purpose */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="invoice-contact-select-label">
                      {Translate.get("InvoiceRef")}
                    </InputLabel>
                    <Select
                      required
                      disabled={!order.canUpdate}
                      labelId="invoice-contact-select-label"
                      id="invoice-contact-select"
                      value={order?.invoiceActorRefId ?? ""}
                      label={Translate.get("InvoiceRef")}
                      onChange={(event) =>
                        handleOrderFieldChanged(
                          "invoiceActorRefId",
                          event.target.value
                        )
                      }
                    >
                      {contacts &&
                        contacts.map((contact) => (
                          <MenuItem
                            key={contact.actorId}
                            value={contact.actorId}
                          >
                            {contact.actorName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="del-address-select-label">
                    {Translate.getActorAddressType(4)}
                  </InputLabel>
                  <Select
                    sx={{ height: "130px" }}
                    multiline
                    rows={4}
                    labelId="del-address-select-label"
                    id="del-address-select"
                    value={order.deliveryAddressId ?? ""}
                    label={Translate.getActorAddressType(4)}
                    onChange={(event) =>
                      handleDeliveryAddressSelected(event.target.value)
                    }
                    disabled={!order.canUpdate}
                  >
                    {(useSeparateReceiverCompany
                      ? receiverCompanyAddresses
                      : addresses) &&
                      (useSeparateReceiverCompany
                        ? receiverCompanyAddresses
                        : addresses
                      ).map((address) => (
                        <MenuItem
                          divider
                          key={address.actorAddressId}
                          value={address.actorAddressId}
                        >
                          <Grid container whiteSpace="pre">
                            <Grid item xs={9}>
                              {address.actorAddressStreet1?.trim()
                                ? address.actorAddressStreet1
                                : " "}
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{
                                  backgroundColor: "#EDEFF1",
                                  padding: "3px",
                                  borderRadius: "3px",
                                  fontSize: "smaller",
                                }}
                              >
                                {Translate.getActorAddressType(
                                  address.actorAddressType
                                )}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressStreet2?.trim()
                                ? address.actorAddressStreet2
                                : " "}
                            </Grid>
                            <Grid item xs={12}>
                              {`${
                                address.actorAddressZipCode?.trim()
                                  ? address.actorAddressZipCode
                                  : " "
                              } ${
                                address.actorAddressCity?.trim()
                                  ? address.actorAddressCity
                                  : " "
                              }`}
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressCountry?.trim()
                                ? address.actorAddressCountry
                                : " "}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    <MenuItem value={-1}>
                      <Grid container whiteSpace="pre">
                        <Grid item container xs={9}>
                          {isEmptyTempAddress() ? (
                            <Grid item xs={12}>
                              <Box
                                height="96px"
                                display="flex"
                                alignItems="center"
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {Translate.getActorAddressType(5)}
                                </Typography>
                              </Box>
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                {order.tempAddress?.street1?.trim()
                                  ? order.tempAddress?.street1
                                  : " "}
                              </Grid>
                              <Grid item xs={12}>
                                {order.tempAddress?.street2?.trim()
                                  ? order.tempAddress?.street2
                                  : " "}
                              </Grid>
                              <Grid item xs={12}>
                                {`${
                                  order.tempAddress?.cityCode?.trim()
                                    ? order.tempAddress?.cityCode
                                    : " "
                                } ${
                                  order.tempAddress?.city?.trim()
                                    ? order.tempAddress?.city
                                    : " "
                                }`}
                              </Grid>
                              <Grid item xs={12}>
                                {order.tempAddress?.country?.trim()
                                  ? order.tempAddress.country
                                  : " "}
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid item container xs={3}>
                          <Grid item xs={12}>
                            <span
                              style={{
                                backgroundColor: "#EDEFF1",
                                padding: "3px",
                                borderRadius: "3px",
                                fontSize: "smaller",
                              }}
                            >
                              {Translate.getActorAddressType(5)}
                            </span>
                          </Grid>
                          {(!isEmptyTempAddress() ||
                            order.deliveryAddressId === -1) && (
                            <Grid
                              item
                              xs={12}
                              justifyContent="center"
                              className="showWhenMenuOpen"
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Edit />}
                                onClick={() =>
                                  handleDeliveryAddressSelected(-1)
                                }
                              >
                                {Translate.get("EditLabel")}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="inv-address-select-label">
                    {Translate.getActorAddressType(1)}
                  </InputLabel>
                  <Select
                    sx={{ height: "130px" }}
                    multiline
                    rows={4}
                    labelId="inv-address-select-label"
                    id="inv-address-select"
                    value={order.invoiceAddressId ?? ""}
                    label={Translate.getActorAddressType(1)}
                    onChange={(event) =>
                      handleOrderFieldChanged(
                        "invoiceAddressId",
                        event.target.value
                      )
                    }
                    disabled={!order.canUpdate}
                  >
                    {addresses &&
                      addresses.map((address) => (
                        <MenuItem
                          divider
                          key={address.actorAddressId}
                          value={address.actorAddressId}
                        >
                          <Grid container whiteSpace="pre">
                            <Grid item xs={9}>
                              {address.actorAddressStreet1?.trim()
                                ? address.actorAddressStreet1
                                : " "}
                            </Grid>
                            <Grid item xs={3}>
                              <span
                                style={{
                                  backgroundColor: "#EDEFF1",
                                  padding: "3px",
                                  borderRadius: "3px",
                                  fontSize: "smaller",
                                }}
                              >
                                {Translate.getActorAddressType(
                                  address.actorAddressType
                                )}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressStreet2?.trim()
                                ? address.actorAddressStreet2
                                : " "}
                            </Grid>
                            <Grid item xs={12}>
                              {`${
                                address.actorAddressZipCode?.trim()
                                  ? address.actorAddressZipCode
                                  : " "
                              } ${
                                address.actorAddressCity?.trim()
                                  ? address.actorAddressCity
                                  : " "
                              }`}
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressCountry?.trim()
                                ? address.actorAddressCountry
                                : " "}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={12}>
              <h4>{Translate.get("OrderDetails")}</h4>
            </Grid>
            <Grid item container xs={12} md={4} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={!order.canUpdate}
                  // Can't require, because some clients need an order confirmation before they can provide order number
                  // required={company?.defaultCustomerOrderNoRequired}
                  fullWidth
                  value={order?.customerOrderNo ?? ""}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) =>
                    handleOrderFieldChanged(
                      "customerOrderNo",
                      event.target.value
                    )
                  }
                  label={Translate.get("YourOrderNo")}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!order.canUpdate}
                  fullWidth
                  value={order?.goodsMarking ?? ""}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) =>
                    handleOrderFieldChanged("goodsMarking", event.target.value)
                  }
                  label={Translate.get("GoodsMarking")}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  disabled={orderLines.some(
                    (ol) => ol.status >= orderStatus.picked
                  )}
                  mask={getCurrentDateMask()}
                  inputFormat={getCurrentInputFormat()}
                  value={order.pickDate ?? null}
                  onChange={(newDate) => {
                    if (isValidDate(newDate)) {
                      handleOrderFieldChanged(
                        "pickDate",
                        toSameDateTimeUTC(newDate)
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Translate.get("PickDate")}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={4} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="delivery-agreement-label">
                    {Translate.get("DeliveryTerms")}
                  </InputLabel>
                  <Select
                    label={Translate.get("DeliveryTerms")}
                    labelId="delivery-agreement-label"
                    disabled={!order.canUpdate}
                    value={order.deliveryAgreement ?? ""}
                    onChange={(event) =>
                      handleOrderFieldChanged(
                        "deliveryAgreement",
                        event.target.value
                      )
                    }
                    endAdornment={
                      order.deliveryAgreement && (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          <IconButton
                            onClick={() =>
                              handleOrderFieldChanged("deliveryAgreement", null)
                            }
                          >
                            <Clear></Clear>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {deliveryAgreements.map((deliveryAgreement) => (
                      <MenuItem
                        key={deliveryAgreement.ePortCodeId}
                        value={deliveryAgreement.ePortCodeId}
                      >
                        {deliveryAgreement.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="mode-of-transport-label">
                    {Translate.get("ModeOfTransport")}
                  </InputLabel>
                  <Select
                    label={Translate.get("ModeOfTransport")}
                    labelId="mode-of-transport-label"
                    disabled={!order.canUpdate}
                    value={order.modeOfTransport ?? ""}
                    onChange={(event) =>
                      handleOrderFieldChanged(
                        "modeOfTransport",
                        event.target.value
                      )
                    }
                    endAdornment={
                      order.modeOfTransport && (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          <IconButton
                            onClick={() =>
                              handleOrderFieldChanged("modeOfTransport", null)
                            }
                          >
                            <Clear></Clear>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {modesOfTransport.map((modeOfTransport) => (
                      <MenuItem
                        key={modeOfTransport.ePortCodeId}
                        value={modeOfTransport.ePortCodeId}
                      >
                        {modeOfTransport.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="payment-agreement-label">
                    {Translate.get("PaymentTerm")}
                  </InputLabel>
                  <Select
                    label={Translate.get("PaymentTerm")}
                    labelId="payment-agreement-label"
                    disabled={!order.canUpdate}
                    value={order.paymentAgreement ?? ""}
                    onChange={(event) =>
                      handleOrderFieldChanged(
                        "paymentAgreement",
                        event.target.value
                      )
                    }
                    endAdornment={
                      order.paymentAgreement && (
                        <InputAdornment
                          sx={{ marginRight: "10px" }}
                          position="end"
                        >
                          <IconButton
                            onClick={() =>
                              handleOrderFieldChanged("paymentAgreement", null)
                            }
                          >
                            <Clear></Clear>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {paymentAgreements.map((paymentAgreement) => (
                      <MenuItem
                        key={paymentAgreement.ePortCodeId}
                        value={paymentAgreement.ePortCodeId}
                      >
                        {paymentAgreement.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container xs={12} md={4} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="currencies-label">
                    {Translate.get("CurrencyLabel")}
                  </InputLabel>
                  <Select
                    label={Translate.get("CurrencyLabel")}
                    labelId="currency-label"
                    disabled={!order.canUpdate}
                    value={order.currency ?? ""}
                    onChange={(event) =>
                      handleOrderFieldChanged("currency", event.target.value)
                    }
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency.code} value={currency.code}>
                        {`${currency.code} ${
                          currency.description
                        } (${formatMoney(0, currency.cultureName, currency.code)
                          .replace("0", "")
                          .replace(".", "")
                          .replace(",", "")
                          .trim()})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!order.canUpdate}
                  fullWidth
                  multiline
                  rows={4}
                  value={order?.extraNotification ?? ""}
                  onChange={(event) =>
                    handleOrderFieldChanged(
                      "extraNotification",
                      event.target.value
                    )
                  }
                  label={Translate.get("ExtraNotificationLabel")}
                  inputProps={{ maxLength: 1048 }}
                />
              </Grid>
            </Grid>
          </Grid>

          {order.orderId > 0 && (
            <Grid item container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={12}>
                <h4>{Translate.get("PaymentDetails")}</h4>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={order?.creditId ? 4 : 6}
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    value={order?.invoicePrice ?? ""}
                    label={Translate.get("InvoicePrice")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    value={order?.invoiceNo ?? ""}
                    label={Translate.get("InvoiceNumber")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    disabled
                    mask={getCurrentDateMask()}
                    inputFormat={getCurrentInputFormat()}
                    value={order.invoiceDate ?? null}
                    onChange={() => {
                      /* Noop just to avoid console errors */
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Translate.get("InvoiceDate")}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={order?.creditId ? 4 : 6}
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    value={order?.invoiceNo ?? ""}
                    label={Translate.get("PaidPrice")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    disabled
                    mask={getCurrentDateMask()}
                    inputFormat={getCurrentInputFormat()}
                    value={order.paymentDate ?? null}
                    onChange={() => {
                      /* Noop just to avoid console errors */
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Translate.get("PaymentDate")}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    disabled
                    mask={getCurrentDateMask()}
                    inputFormat={getCurrentInputFormat()}
                    value={order.lastPaymentDate ?? null}
                    onChange={() => {
                      /* Noop just to avoid console errors */
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={Translate.get("DueDate")}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {order?.creditId && (
                <Grid item container xs={12} md={4} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      value={order?.creditAmount ?? ""}
                      label={Translate.get("CreditNoteAmount")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      value={order?.creditId ?? ""}
                      label={Translate.get("CreditNote")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      disabled
                      mask={getCurrentDateMask()}
                      inputFormat={getCurrentInputFormat()}
                      value={order.creditDate ?? null}
                      onChange={() => {
                        /* Noop just to avoid console errors */
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={Translate.get("CreditNoteDate")}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: Translate.get("DatePlaceholder"),
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <DataGrid
                className={
                  "dgDynIconText dgSmall" +
                  (orderLines.length === 0 ? " hideOverlayWrapper" : "")
                }
                rows={orderLines.filter(
                  (ol) =>
                    ol.status === undefined ||
                    ol.status === null ||
                    ol.status > orderStatus.canceled
                )}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => `${row.partId}_${row.orderLineId}`}
                autoHeight={true}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: () => <div></div>,
                }}
                componentsProps={{
                  columnsPanel: { className: "customColumnPanel" },
                  filterPanel: { className: "customfilterPanel" },
                  panel: { className: "customPanel" },
                }}
                localeText={localizedTextsMap}
                disableColumnMenu
                disableDensitySelector={true}
                density="comfortable"
                hideFooter={true}
              />
            </Grid>
            <Grid item xs={12} marginLeft={2}>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={
                    !order.canUpdate ||
                    !order.actorId ||
                    isServiceAgreement ||
                    isEPortLicense
                  }
                  onClick={handleAddOrderLine}
                  startIcon={<Add />}
                >
                  {Translate.get("PartLabel")}
                </Button>
                <Button
                  disabled={
                    !order.canUpdate ||
                    isServiceAgreement ||
                    isEPortLicense ||
                    orderLines.length === 0 ||
                    !orderLines
                      .filter((ol) => ol.status > 0)
                      .some(
                        (ol) =>
                          !!ol.part && !isFreightPartType(ol.part.partType)
                      )
                  }
                  onClick={handleAddOrEditFreight}
                  startIcon={
                    orderLines
                      .filter((ol) => ol.status > 0)
                      .some((ol) => isFreightPartType(ol.part?.partType)) ? (
                      <Edit />
                    ) : (
                      <Add />
                    )
                  }
                >
                  {Translate.get("Freight")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MenuToggleButton
          label={Translate.get("Priority")}
          states={[
            {
              value: priority.high,
              label: Translate.getPriority(priority.high),
              icon: <Flag sx={{ color: "red" }} />,
              onClick: () => handleOrderFieldChanged("priority", priority.high),
            },
            {
              value: priority.normal,
              label: Translate.getPriority(priority.normal),
              icon: <FlagOutlined />,
              onClick: () =>
                handleOrderFieldChanged("priority", priority.normal),
            },
            {
              value: priority.onHold,
              label: Translate.getPriority(priority.onHold),
              icon: <Lock />,
              onClick: () =>
                handleOrderFieldChanged("priority", priority.onHold),
            },
          ]}
          value={order.priority ?? priority.normal}
        />
        <Box>
          <LoadingButton
            loading={documentLoading}
            startIcon={<MoreHoriz />}
            variant="outlined"
            id="basic-button"
            aria-controls={documentOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={documentOpen ? "true" : undefined}
            onClick={(e) => setDocumentAnchorEl(e.currentTarget)}
          >
            {Translate.get("Document")}
          </LoadingButton>
          <Menu
            open={!!documentOpen}
            anchorEl={documentAnchorEl}
            onClose={() => setDocumentAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={async () => {
                setDocumentAnchorEl(null);
                setDocumentLoading(true);
                await downloadOrderConfirmation();
                setDocumentLoading(false);
              }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText>{Translate.get("OrderConfirmation")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setDocumentAnchorEl(null);
                setDocumentLoading(true);
                await downloadDeliveryNotification();
                setDocumentLoading(false);
              }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText>
                {Translate.get("DeliveryNotification")}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                setDocumentAnchorEl(null);
                setDocumentLoading(true);
                await downloadDeliveryNote();
                setDocumentLoading(false);
              }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText>{Translate.get("DeliveryNoteLabel")}</ListItemText>
            </MenuItem>

            <MenuItem
              disabled={
                !company ||
                !order.actorId ||
                !order.orderId ||
                (company.isMAToyotaClient && company.isToyotaInvoice)
              }
              onClick={() => {
                setDocumentAnchorEl(null);
                handleEmailOrderConfirmation();
              }}
            >
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText>{Translate.get("OrderConfirmation")}</ListItemText>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setDocumentAnchorEl(null);
                handlePrintDeliveryAddress();
              }}
            >
              <ListItemIcon>
                <Print />
              </ListItemIcon>
              <ListItemText>{Translate.getActorAddressType(4)}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Button onClick={handleClose}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleSaveOrSendOrder}
          disabled={
            !company ||
            (isExternalDataIdRequired() && !company.externalDataId) ||
            !order.canUpdate ||
            !hasOrderChanged ||
            !orderLines ||
            orderLines.length === 0 ||
            !order.actorRefId ||
            (useSeparateInvoiceReference && !order.invoiceActorRefId)
          }
        >
          {order.orderId ? Translate.get("Save") : Translate.get("CreateOrder")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
