import React, { useEffect, useState } from 'react';
import { useApi } from '../../utils/Api';
import Paper from '@mui/material/Paper';
import Preloader from '../Preloader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import EPortAvatar from "../EPortAvatar";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Translate from "../../utils/Translate";
import { useMediaQuery } from "@mui/material";
import { tryCreatePersonnummer, getPersonummerFormatSettingsFromString } from '../../utils/personnummer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { deleteCookie, getCookie, isNullOrEmpty } from './Common';

export function Form(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  const [gdprText, setGdprText] = useState("");

  const [name, setName] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [externalActorNo, setExernalActorNo] = useState("");
  const [actorEmail, setActorEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [actorAddressStreet2, setActorAddressStreet2] = useState("");
  const [actorAddressCity, setActorAddressCity] = useState("");
  const [actorAddressZipCode, setActorAddressZipCode] = useState("");
  const [actorFreeText, setActorFreeText] = useState("");
  const [nationality, setNationality] = useState("");
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [GDPRApproved, setGDPRApproved] = useState(false);

  const [imageResponse, setImageResponse] = useState(null);

  const [fields, setFields] = useState([]);
  const [actorId, setActorId] = useState(null);

  const [courseBookingId, setCourseBookingId] = useState(null);
  const [isActorSpecific, setIsActorSpecific] = useState(null);
  const [orgNoFormat, setOrgNoFormat] = useState(null);
  const [orgNoSettings, setOrgNoSettings] = useState(null);

  const [oldInvalidPersonalNumber, setOldInvalidPersonalNumber] = useState(null);

  const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState(false);

  const smallScreen = useMediaQuery("(max-width:900px)");
  const [dataFetched, setDataFetched] = useState(true);
  const api = useApi();

  useEffect(() => {
    async function effect() {

      // Fall 1. Självregistrering på kursnivå har öppnats
      if (!props.selfRegSettings.isActorSpecific) {
        setIsActorSpecific(false);

        // Hämta data från cookies
        let token = getCookie("selfRegToken");
        setToken(token)

        let id = getCookie("selfRegId");
        setId(id);

        setCourseBookingId(props.courseBookingId);
        setActorId(props.actorId);
        
        setFields(props.selfRegSettings.fields);

        await NationalityOptions(props.selfRegSettings.fields);

        setOrgNoFormat(props.selfRegSettings.orgNoFormat);
        setActorFields(props.existingActor);

        if (props.existingActor?.actorId != null) {
          setActorId(props.existingActor.actorId);
        }

        setGdprText(getSpecificDataProtectionTextOrDefault(props.selfRegSettings.gdprText));
      }


      // Fall 2. En specifik elevs kod eller QR-kod har använts
      else {
        setIsActorSpecific(true);
        let token = getCookie("selfRegToken");
        setToken(token)

        let id = getCookie("selfRegId");
        setId(id);

        setFields(props.selfRegSettings.fields);

        await NationalityOptions(props.selfRegSettings.fields);

        setOrgNoFormat(props.selfRegSettings.orgNoFormat);
        setGdprText(getSpecificDataProtectionTextOrDefault(props.selfRegSettings.gdprText));

        let actor = await getActor(token, id);
        setActorId(actor.actorId);
        setActorFields(actor);
      }

      let orgNoSettings = await getPersonummerFormatSettingsFromString(props.selfRegSettings.orgNoFormat);
      setOrgNoSettings(orgNoSettings);

      if (props.bankIdToken !== "") {
        setName(capitalizeFirstLetter(props.givenName) + " " + capitalizeFirstLetter(props.surName));
        setOrgNo(await formatPersonalNumer(props.ssn, orgNoSettings));
      }
    }
    effect();
  }, []);

  async function getActor(qrToken, id) {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/actor/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        qrToken
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    return response;
  }

  async function getNationalityOptions() {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}countries?languageCode=29`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        token
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    return response;
  }

  function setActorFields(actor) {
    if (actor === null) {
      return;
    }

    setName(actor.actorName);
    setOrgNo(actor.orgNo);
    setExernalActorNo(actor.externalActorNo);
    setActorEmail(actor.actorEmail);
    setMobilePhone(actor.mobilePhone?.actorPhoneNumber);
    setActorAddressStreet2(actor.postalAddress?.actorAddressStreet2);
    setActorAddressZipCode(actor.postalAddress?.actorAddressZipCode);
    setActorAddressCity(actor.postalAddress?.actorAddressCity);
    setProfilePictureUrl(actor.profilePictureUrl);
    setNationality(actor.nationality);
  }

  async function uploadImage(actorId, imageId) {
    api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}UploadActorImageDatabase`,
      { actorId: actorId, imageId: imageId }, 'POST', token
    );
  }

  async function addStudent() {
    const reqObj = {
      id: id,
      reservationToken: getCookie("selfRegReservationToken"),
      bankIdToken: props.bankIdToken,
      courseBookingId: courseBookingId,
      actor: {
        actorId: actorId,
        actorName: name,
        externalActorNo: externalActorNo,
        birthDate: null,
        orgNo: orgNo,
        actorEmail: actorEmail,
        isConnectedToUser: false,
        observationNote: null,
        actorFreeText: actorFreeText !== "" ? [{ actorFreeTextId: 0, textNote: actorFreeText, actorId: actorId }] : null,
        nationality: nationality,
        mobilePhone: {
          actorId: actorId,
          actorPhoneNumber: mobilePhone,
        },
        postalAddress: {
          actorId: actorId,
          actorAddressStreet2: actorAddressStreet2,
          actorAddressCity: actorAddressCity,
          actorAddressZipCode: actorAddressZipCode,
          actorAddressCountry: ""
        },
        HasApprovedGDPR: GDPRApproved
      }

    };

    try {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/book`,
        reqObj,
        'POST',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        token
      );
      setIsLoading(false);
      if (response.isSuccessful) {
        props.setMode("Success");
        deleteCookie("selfRegToken");
        deleteCookie("selfRegId");
      } else {
        if (response.errorMessageTranslationKey === "SessionExpired") {
          setShowSessionExpiredDialog(true);
        }

        setErrorMessage(response.errorMessageTranslationKey);
      }
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
  }

  async function addSpecificStudent(actorId) {
    const actor = {
      actorId: actorId,
      bankIdToken: props.bankIdToken,
      actorName: name,
      externalActorNo: externalActorNo,
      birthDate: null,
      orgNo: orgNo,
      actorEmail: actorEmail,
      isConnectedToUser: false,
      observationNote: null,
      actorFreeText: actorFreeText !== "" ? [{ actorFreeTextId: 0, textNote: actorFreeText, actorId: actorId }] : null,
      nationality: nationality,
      mobilePhone: {
        actorPhoneNumber: mobilePhone,
        actorId: actorId
      },
      postalAddress: {
        actorId: actorId,
        actorAddressStreet2: actorAddressStreet2,
        actorAddressCity: actorAddressCity,
        actorAddressZipCode: actorAddressZipCode,
        actorAddressCountry: ""
      },
      HasApprovedGDPR: GDPRApproved
    };

    let reqObj = {
      actor: actor,
      bankIdToken: props.bankIdToken,
      id: getCookie("selfRegId")
    }

    try {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/update/specific/${id}`,
        reqObj,
        'POST',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        token
      );
      setIsLoading(false);
      if (response.isSuccessful) {
        props.setMode("Success");
        deleteCookie("selfRegToken");
        deleteCookie("selfRegId")
      }
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
  }

  async function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    if (imageResponse) {
      await uploadImage(actorId, imageResponse.imageId);
    }

    if (isActorSpecific) {
      await addSpecificStudent(actorId);
    } else {
      await addStudent();
    }
  }

  function handleCheckboxChange(event) {
    let b = event.target.value === "true";
    setGDPRApproved(!b);
  }


  async function formatPersonalNumer(orgNo, orgNoSettings) {
    let res = await tryCreatePersonnummer(orgNo, orgNoSettings.longFormat, orgNoSettings.hyphen, orgNoSettings.dontFormat);
    if (res.isValid) {
      return res.formattedPersonalNumber;
    }

    return orgNo;
  }

  async function handleOrgNoBlur(e) {
    if (orgNo === oldInvalidPersonalNumber) {
      e.target.setCustomValidity("");
      e.target.reportValidity();
      return;
    }

    let res = await tryCreatePersonnummer(e.target.value, orgNoSettings.longFormat, orgNoSettings.hyphen, orgNoSettings.dontFormat);
    if (e.target.value === "" || res === null) {
      return;
    }
    if (!res.isValid) {
      setOldInvalidPersonalNumber(e.target.value);
      e.target.setCustomValidity(Translate.get("SsnDeviate"));
      e.target.reportValidity();

    } else if (res.formattedPersonalNumber !== null) {
      setOrgNo(res.formattedPersonalNumber);
      e.target.setCustomValidity("");
      e.target.reportValidity();
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function sortNationalityOptions(array) {
    const swedenIndex = array.findIndex(item => item.alpha2 === "SE");
    const sweden = array.splice(swedenIndex, 1)[0];

    array.sort((a, b) => a.name.localeCompare(b.name));

    array.unshift(sweden);
    return array;
  }


  function getSpecificDataProtectionTextOrDefault(specificText) {
    if (!isNullOrEmpty(specificText)) {
      return specificText;
    }

    return Translate.get("GDPRInfo")
  }

  async function NationalityOptions(fields) {
    if (fields.some(item => item.fieldName === "Nationality")) {
      let res = await getNationalityOptions();
      let sortedNationalityOptions = sortNationalityOptions(res);
      setNationalityOptions(sortedNationalityOptions);
    }
  }

  return (
    <div className="stdPage" style={{ display: 'flex', justifyContent: 'center' }}>
      {(!dataFetched || isLoading) && <Preloader />}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>

          <Paper elevation={3} sx={{ p: smallScreen ? 3 : 8, width: smallScreen ? "95vw" : 800, mt: 1, mb: 1 }}>
            <p><b>{Translate.get("RegisterYou")}</b></p>

            <form onSubmit={handleSubmit} className="qrForm">

              {fields.some(f => f.fieldName === "ActorName" && f.markedAsVisible === true) && <TextField label={Translate.get("Name")} placeholder={Translate.get("FullNamePlaceholder")} id="Name" value={name} onChange={(e) => setName(e.target.value)} required={fields.find(f => f.fieldName === "ActorName").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "OrgNo" && f.markedAsVisible === true) && <TextField
                label={Translate.get("SocialSecurityNumber")}
                id="orgNo" value={orgNo}
                onChange={(e) => {
                  setOrgNo(e.target.value);
                  e.target.setCustomValidity("");
                  e.target.reportValidity();
                }}
                sx={{ mt: 2 }}
                placeholder={orgNoFormat !== "Undefined" ? Translate.get(orgNoFormat) : ""}
                onBlur={handleOrgNoBlur}
                required={fields.find(f => f.fieldName === "OrgNo").markedAsRequired ?? false}
                inputProps={{ minLength: 6 }}
                disabled={props.existingActor?.orgNo != null || props.bankIdToken != null && props.bankIdToken != ""}
              >
              </TextField>}
              {fields.some(f => f.fieldName === "ExternalActorNo" && f.markedAsVisible === true) && <TextField label={Translate.get("EmployeeNo")} id="externalActorNo" value={externalActorNo} onChange={(e) => setExernalActorNo(e.target.value)} required={fields.find(f => f.fieldName === "ExternalActorNo").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "ActorEmail" && f.markedAsVisible === true) && <TextField label={Translate.get("Email")} type="email" id="actorEmail" value={actorEmail} onChange={(e) => setActorEmail(e.target.value)} required={fields.find(f => f.fieldName === "ActorEmail").markedAsRequired ?? false} sx={{ mt: 2 }} ></TextField>}
              {fields.some(f => f.fieldName === "MobilePhone" && f.markedAsVisible === true) && <TextField label={Translate.get("Mobile")} type="phone" id="mobilePhone" value={mobilePhone} onChange={(e) => setMobilePhone(e.target.value)} required={fields.find(f => f.fieldName === "MobilePhone").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "ActorAddressStreet2" && f.markedAsVisible === true) && <TextField label={Translate.get("Address2")} id="actorAddressStreet2" value={actorAddressStreet2} onChange={(e) => setActorAddressStreet2(e.target.value)} required={fields.find(f => f.fieldName === "ActorAddressStreet2").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "ActorAddressCity" && f.markedAsVisible === true) && <TextField label={Translate.get("City")} id="actorAddressCity" value={actorAddressCity} onChange={(e) => setActorAddressCity(e.target.value)} required={fields.find(f => f.fieldName === "ActorAddressCity").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "ActorAddressZipCode" && f.markedAsVisible === true) && <TextField label={Translate.get("ZipCode")} id="actorAddressZipCode" value={actorAddressZipCode} onChange={(e) => setActorAddressZipCode(e.target.value)} required={fields.find(f => f.fieldName === "ActorAddressZipCode").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}
              {fields.some(f => f.fieldName === "ActorFreeText" && f.markedAsVisible === true) && <TextField label={Translate.get("FreeText")} id="actorFreeText" value={actorFreeText} onChange={(e) => setActorFreeText(e.target.value)} required={fields.find(f => f.fieldName === "ActorFreeText").markedAsRequired ?? false} sx={{ mt: 2 }}></TextField>}

              {fields.some(f => f.fieldName === "Nationality" && f.markedAsVisible === true) && (
                <FormControl sx={{ mt: 2 }} required={fields.find(f => f.fieldName === "Nationality").markedAsRequired ?? false}>
                  <InputLabel id="nationalityLabel">{Translate.get("Nationality")}</InputLabel>
                  <Select
                    labelId="nationalityLabel"
                    id="nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    label={Translate.get("Nationality")}
                  >
                    {nationalityOptions.map((option) => (
                      <MenuItem key={option.alpha2} value={option.alpha2}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {fields.some(f => f.fieldName === "ActorImage" && f.markedAsVisible === true) && <EPortAvatar
                avatarWidth={160}
                avatarHeight={160}
                fontSize={72}
                //displayName={formData.actorName ? formData.actorName : "?"}
                pictureUrl={profilePictureUrl + ""}
                onImageUploaded={(resp) => setImageResponse(resp)}
                token={token}
                required={fields.find(f => f.fieldName === "ActorImage").markedAsRequired ?? false} imageType={"person"} />}

              <aside className='MuiCallout-root MuiCallout-warning'> {gdprText}</aside>
              <FormControlLabel control={<Checkbox value={GDPRApproved} onChange={handleCheckboxChange} required={true} />} label={Translate.get("IApprove")} />

              {errorMessage !== "" && <Alert severity="error" sx={{ mt: 1 }}>{Translate.get(errorMessage)}</Alert>}
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>{Translate.get("SaveInformation")}</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>

      {showSessionExpiredDialog && <Dialog
        open={true}
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get("SessionExpired")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translate.get("BookingsMustBeCompleted")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => window.location.reload(true)}>
            {Translate.get("TryAgain")}
          </Button>
        </DialogActions>
      </Dialog>}
    </div>
  );
}

